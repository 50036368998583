import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/index').then(mod => mod.LoginModule)
  },
  {
    path: 'manage-employees',
    loadChildren: () => import('./modules/manage-employee/index').then(mod => mod.ManageEmployeeModule)
  },
  {
    path: 'employer',
    loadChildren: () => import('./modules/employer-module/index').then(mod => mod.EmployerTabModule)
  },
  {
    path: 'password-forgot',
    loadChildren: () => import('./modules/forgot-password/index').then(mod => mod.ForgotPasswordModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/index').then(mod => mod.ContactUsModule)
  },
  {
    path: 'set-password',
    loadChildren: () => import('./modules/set-password/index').then(mod => mod.AppSetPasswordModule)
  },
  {
    path: 'employee-inbox',
    loadChildren: () => import('./modules/app-messaging-inbox/index').then(mod => mod.AppMessagingInboxModule)
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
